export default [
  {
    header: "Menu",
  },

  {
    title: "dashboard",
    icon: "ActivityIcon",
    route: "nt-reports-people",
  },
];
